<template>
  <div>
    <StepProgress :steps="[[null, 'icn_account_off.svg', 'icn_account_on.svg'], ['icn_school_off.svg', 'icn_school_on.svg', null]]" :current-step="1" />

    <!-- Title and subtitle -->
    <h1 class="auth-title search-title">{{ $t('SELECT_SCHOOL.SEARCH') }}</h1>
    <p class="auth-subtitle search-subtitle">{{ $t('SELECT_SCHOOL.SEARCH_INFO') }}</p>

    <Search :search-title="$t('SEARCH.INPUT_TITLE')"
            :placeholder="$t('SEARCH.PLACEHOLDER')"
            :search-id="'search1'"
            :manage-search="manageSearch"
            :clear-search="clearSearch"
            :search-results="searchResults">

      <!-- Result indicator -->
      <template #content-below="searchedTerm">
        <p v-if="searchedTerm && searchResults" class="result-indicator">
          {{ searchResults.length }} {{ $t('RESULTS') }}
        </p>
      </template>
    </Search>

    <!-- Search results -->
    <SearchResults :search-results="searchResults" :on-item-click="onItemClick"/>

    <!-- No results indication. -->
    <NoSearchResults v-if="lastSearch && lastSearch.length >= 3 && searchResults && searchResults.length === 0"/>

    <p
      v-if="inviteStateError"
      class="text-error"
    >
      Dit account is recent uitgenodigd door een andere gebruiker. Bekijk je e-mail om het uitnodigingsproces af te ronden.
    </p>

    <EnterBrin v-if="isBrinShown && selectedSchool"
               :close-brin="toggleBrinPopup"
               :city="selectedSchool.city"
               :street-name="selectedSchool.streetName"
               :house-number="selectedSchool.houseNumber"
               :school-name="selectedSchool.name"
               :school-id="selectedSchool.id"
               :email="teacherEmail"
               :on-valid-brin="finishAccountRegistration"
    />
  </div>
</template>

<script>
import Search from '../../../../components/elements/selectSchools/Search'
import EnterBrin from '../../../../components/elements/brin/EnterBrin'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { SET_TEACHER_SCHOOL, SEARCH_SCHOOLS } from '@/store/modules/auth/actions'
import { computed } from '@vue/reactivity'
import SearchResults from '@/components/elements/selectSchools/SearchResults'
import NoSearchResults from '@/components/elements/selectSchools/NoSearchResults'
import { RESET_SEARCHED_SCHOOLS } from '@/store/modules/auth/mutations'
import { ROUTE_NAMES_AUTH } from '../../../../router/modules/auth'
import { useRouter } from 'vue-router'
import StepProgress from '@/components/elements/StepProgress'

export default {
  name: 'SelectSchool',
  components: {
    NoSearchResults,
    SearchResults,
    EnterBrin,
    Search,
    StepProgress
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    /** Item box **/
    const selectedSchool = ref({})

    /** Brin number **/
    const isBrinShown = ref(false)

    function toggleBrinPopup() {
      isBrinShown.value = !isBrinShown.value
    }

    const registerFormData = JSON.parse(localStorage.getItem('register_teacher_form'))
    const registerFormResponse = JSON.parse(localStorage.getItem('register_teacher_response'))
    const teacherEmail = registerFormData.email;
    const inviteStateError = ref(false)


    function finishAccountRegistration() {
      inviteStateError.value = false

      localStorage.removeItem('register_teacher_form')
      localStorage.removeItem('register_teacher_response')
      if (registerFormResponse.emailVerified) {
        router.push({ name: ROUTE_NAMES_AUTH.LOGIN })
      } else {
        router.push({ name: ROUTE_NAMES_AUTH.REGISTER_EMAIL_SENT, query: { email: registerFormData.email } })
      }

    }

    const lastSearch = ref('')

    /** Search **/
    function manageSearch(data) {
      lastSearch.value = data
      if (!data) {
        clearSearch()
      } else {
        const payload = {
          searchQuery: data,
          page: 1
        }
        searchSchool(payload)
      }
    }

    function clearSearch() {
      // Reset the stored searched schools.
      store.commit(RESET_SEARCHED_SCHOOLS, undefined)
    }

    if (registerFormData.inviteData) {
      selectedSchool.value = registerFormData.inviteData.school
      toggleBrinPopup()
    }

    function onItemClick(item) {
      selectedSchool.value = item
      toggleBrinPopup()
    }

    /** Search Schools **/
    const searchResults = computed(() => store.getters.getSearchedSchools.items ?? [])

    function searchSchool(payload) {
      return store.dispatch(SEARCH_SCHOOLS, payload)
    }

    /** Register **/
    function registerTeacher(data) {
      return store.dispatch(SET_TEACHER_SCHOOL, data)
    }

    return {
      /** Brin number **/
      isBrinShown,
      toggleBrinPopup,
      finishAccountRegistration,

      /** Search **/
      manageSearch,
      clearSearch,

      /** Item box **/
      onItemClick,
      selectedSchool,
      lastSearch,

      inviteStateError,

      /** Search Schools **/
      searchResults,

      teacherEmail
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.search-title {
  margin-bottom: rem(18);
}

.search-subtitle {
  margin-bottom: rem(21);
}

.helpdesk {
  margin-top: rem(15);
}

.result-indicator {
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(22);
  margin-bottom: rem(21);
  margin-top: rem(7);
  opacity: 0.6;
  text-align: left;
}
</style>
