<template>
  <div class="no-results-wrapper">
    <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
    <img src="../../../assets/img/img_noresult.png" class="icon" alt="No results"/>

    <p class="text">{{ $t('SEARCH.NO_RES_TEXT') }}</p>
    <a href="mailto:info@mediamasters.nl"
       title="info@mediamasters.nl"
       referrerpolicy="no-referrer">
      {{ $t('SEARCH.NO_RES_LINK') }}
    </a>
    <slot name="content"/>
  </div>
</template>

<script>
import { ROUTE_NAMES_AUTH } from '../../../router/modules/auth'

export default {
  name: 'NoSearchResults',
  setup() {
    return {
      ROUTE_NAMES_AUTH
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.no-results-wrapper {
  .icon {
    height: auto;
    margin-bottom: rem(70);
    opacity: 0.4;
    width: rem(188);
  }

  .text {
    font-size: rem(16);
    letter-spacing: 0;
    line-height: rem(19);
    margin-bottom: rem(8);
  }

  .link {
    color: var(--blue_link);
    font-size: rem(16);
    font-weight: 500;
    text-decoration: none;
  }
}
</style>
