<template>
  <div>
    <div class="input-wrapper">
      <label for="brinNumber" class="brin-label">
        <input id="brinNumber"
               ref="brinInput"
               v-model="brin"
               :class="{'input-error': error}"
               name="brinNumber"
               maxlength="6"
               type="text"
               class="brin-input brin-single"
               placeholder="02AB(34)"/>
      </label>
    </div>

    <div v-if="error" class="error-box">
      <p class="error">{{ $t('BRIN.ERROR') }}</p>
      <a class="link-main" href="mailto:info@mediamasters.nl">{{ $t('HELPDESK_PROMPT.LINK') }}</a>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'BrinInput',
  props: {
    error: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const brin = ref(null)
    const brinInput = ref(null)


    return {
      brin,
      brinInput,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

// TODO: brin-input and brin-single can be merged into a single input when design is final.
.brin-input {
  border: none;
  border-radius: rem(8);
  background-color: white;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: var(--blue_dark);
  font-family: var(--font-primary);
  font-size: rem(34);
  font-weight: 600;
  height: rem(97);
  line-height: rem(34);
  padding: rem(30) rem(26);
  width: rem(76);

  &::placeholder {
    color: var(--blue_dark);
    font-size: rem(34);
    line-height: rem(34);
    opacity: 0.2;
  }

  &:focus {
    outline: none;
  }
}

.brin-single {
  letter-spacing: rem(12);
  padding: rem(28) rem(46);
  max-width: rem(340);
  text-align: center;
  width: 100%;
}

.brin-label {
  &:not(:last-child) {
    margin-right: rem(12);
  }
}

.input-error {
  border: 1px solid var(--red_error);
  color: var(--red_error);
}

.error-box {
  .error {
    color: var(--red_error);
    font-size: rem(16);
    letter-spacing: 0;
    line-height: rem(19);
    margin-top: rem(18);
    margin-bottom: rem(18);
    text-align: center;
  }
}
</style>
