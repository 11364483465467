<template>
  <div class="search-results-wrapper">
    <div class="inner-box">
      <!-- V-for here. Just for demonstration. -->
      <ItemBox v-for="suggestion in searchResults"
               :key="suggestion.id"
               class="result-item"
               :text="suggestion.name"
               :subtext="suggestion.streetName + ' ' + suggestion.houseNumber + ', ' + suggestion.city + ', ' + suggestion.province"
               @click="onItemClick(suggestion)"/>
    </div>

    <!-- Bottom overlay -->
    <!-- TODO: When the user reaches the bottom, the overlay should disappear. -->
    <div class="bottom-overlay"></div>
  </div>
</template>

<script>
import ItemBox from '@/components/elements/ItemBox'

export default {
  name: 'SearchResults',
  components: {
    ItemBox
  },
  props: {
    searchResults: {
      type: Array,
      required: true
    },
    onItemClick: {
      type: Function,
      required: true
    },
    onSearchInput: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.search-results-wrapper {
  position: relative;

  .inner-box {
    padding-bottom: rem(52);
    padding-right: rem(24);
    overflow-y: auto;
    max-height: rem(350);
    scrollbar-color: #C6D0DD;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      border-radius: rem(8);
      width: rem(14);
    }

    &::-webkit-scrollbar-track {
      background-color: white;
      border-radius: rem(8);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #C6D0DD;
      border-radius: rem(8);
      border: rem(3) solid white;
    }
  }

  .bottom-overlay {
    @include position(absolute, $right: rem(14), $bottom: 0, $left: 0);
    background: linear-gradient(180deg, rgba(244, 246, 251, 0) 0%, #F4F6FB 100%);
    height: 30%;
    pointer-events: none;
  }

  .result-item {
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
}

</style>
