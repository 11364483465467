<template>
  <div class="brin-wrapper">
    <!-- Overlay -->
    <div class="overlay"></div>

    <!-- Popup content -->
    <div class="content">
      <!-- Title and subtitle -->
      <h1 class="brin-title">{{ $t('BRIN.TITLE') }}</h1>
      <p class="brin-subtitle">
        {{ $t('BRIN.SUBTITLE_1') }} <strong>{{ schoolName }}</strong> {{ $t('BRIN.SUBTITLE_2') }}
        <strong>{{ streetName }} {{ houseNumber }}</strong> {{ $t('BRIN.SUBTITLE_3') }} <strong>{{ city }}.</strong>
      </p>

      <!-- Brin Input fields -->
      <BrinInput ref="brinElem" :error="brinError"/>

      <button type="button" class="pink-button link-account" @click="validateBrin()">
        {{ $t('BRIN.LINK_ACCOUNT') }}
      </button>

      <!-- Choose another school -->
      <!-- On click, the BRIN popup closes and the user is able to select another school. -->
      <p class="link-other" @click="closeBrin()">{{ $t('BRIN.CHOOSE_OTHER') }}</p>
    </div>
  </div>
</template>

<script>
import BrinInput from './BrinInput'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { SET_TEACHER_SCHOOL } from '@/store/modules/auth/actions'

export default {
  name: 'EnterBrin',
  components: {
    BrinInput
  },
  props: {
    closeBrin: {
      type: Function,
      required: true
    },
    schoolId: {
      type: [String, Number],
      required: true
    },
    schoolName: {
      type: String,
      required: true
    },
    streetName: {
      type: String,
      required: true
    },
    houseNumber: {
      type: String,
      required: true
    },
    city: {
      type: String,
      required: true
    },
    onValidBrin: {
      type: Function,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const brinElem = ref(null)
    const brinError = ref(false)

    function validateBrin() {
      const brinInput = brinElem.value.brin
      checkBrin(brinInput).then(response => {
        // If BRIN matches the school id, we can create the account.
        // If it does not, we should display an error.
        if (response) {
          setBrinError(false)
          props.onValidBrin()
        } else {
          setBrinError(true)
        }
      })
    }

    function setBrinError(value) {
      brinError.value = value
    }

    function checkBrin(brinNumber) {
      return store.dispatch(SET_TEACHER_SCHOOL, { brin: brinNumber, schoolId: props.schoolId, email: props.email })
    }

    return {
      brinError,
      brinElem,
      validateBrin
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.brin-wrapper {
}

.overlay {
  @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: 100);
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
}

.content {
  @include position-elevation(absolute, $top: 50%, $left: 50%, $elevation: 150);
  background-color: var(--gray_light);
  border-radius: rem(10);
  padding: rem(40) rem(53);
  transform: translate(-50%, -50%);
  width: rem(527);
}

.brin-title {
  font-size: rem(24);
  letter-spacing: 0;
  line-height: rem(28);
  margin-bottom: rem(15);
  text-align: center;
}

.brin-subtitle {
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(22);
  margin-bottom: rem(32);
  text-align: center;
}

.link-account {
  margin-bottom: rem(32);
  margin-top: rem(32);
}

.link-other {
  @include hover-active-pointer();
  color: var(--blue_link);
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(19);
}
</style>
